import { logEvent } from 'firebase/analytics';
import { analytics } from '../utilities/firebase';

export type JobPageTracking = {
  pageView: boolean;
};

export function trackJobFormPageView() {
  // Log the page view event with the custom ID
  logEvent(analytics, 'theraplo_form_page_view');
}

export function trackJobFormSubmission() {
  // Log the page view event with the custom ID
  logEvent(analytics, 'theraplo_submission');
}
