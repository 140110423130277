import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Select,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { addDoc, collection } from 'firebase/firestore';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { db } from '../utilities/firebase';
import {
  JobPageTracking,
  trackJobFormPageView,
  trackJobFormSubmission,
} from './tracking';

const RedAsterisk = () => (
  <Text color="red" style={{ display: 'inline' }}>
    *
  </Text>
);

type JobPackageOption = 'basic' | 'premium' | 'pro';

const jobPackages: Record<JobPackageOption, string> = {
  basic: 'Starter',
  pro: 'Profesjonalny',
  premium: 'Enterprise',
};

export function ContactPage() {
  const tracked = useRef<JobPageTracking>({
    pageView: false,
  });
  const toast = useToast();
  useEffect(() => {
    if (!tracked.current.pageView) {
      trackJobFormPageView();
      tracked.current.pageView = true;
    }
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setError,
    setValue,
    clearErrors,
    trigger,
    watch,
  } = useForm({
    criteriaMode: 'all',
  });

  const onSubmit = async (values: any) => {
    try {
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: '+48' + values.phone,
        companyName: values.companyName,
        companyWebsite: values.companyWebsite,
        companySize: values.companySize,
        package: values.package,
        createdAt: new Date(),
      };
      await addDoc(collection(db, 'registration'), payload);
      toast({
        title: 'Dziękujemy!',
        description:
          'Przygotujemy teraz Twoje środowisko i skontaktujemy się z Tobą w ciągu kilku godzin.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      console.error(e);
      toast({
        title: 'Błąd!',
        description: 'Coś poszło nie tak.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return false;
    } finally {
      trackJobFormSubmission();
    }
  };

  return (
    <>
      <Center mt="20px" mb="20px">
        <Box
          bg="background.100"
          backgroundColor={'white'}
          borderWidth="1px"
          borderRadius="20px"
          borderColor={'#e4e4ed'}
          position={'relative'}
          width="550px"
          m={2}
        >
          <Box position={'relative'}>
            <Box
              position={'absolute'}
              width="200px"
              left="50%"
              top="10px"
              borderRadius={'5px'}
              p="15px"
              backgroundColor="white"
              borderColor={'#e4e4ed'}
              zIndex={4}
              transform="translateX(-50%)"
            >
              <Image src="https://theraplo.com/wp-content/uploads/2023/09/download-1-1024x286.png" />
            </Box>
          </Box>
          <VStack spacing="5" py="4" px="8" maxW={600}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box position="relative" paddingY={5} mt={20}>
                <Divider />
                <AbsoluteCenter bg="white" px="4" textAlign="center">
                  Rejestracja
                </AbsoluteCenter>
              </Box>
              <Box mb={10}>
                <Text fontSize="sm" align="center" fontStyle="italic">
                  Wypełnij poniższy formularz, aby rozpocząć!
                </Text>
              </Box>

              <FormControl isInvalid={!!errors.firstName}>
                <FormLabel htmlFor="firstName" color="text.500" mb="20px">
                  Imię
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="firstName"
                  placeholder="Twoje imię"
                  {...register('firstName', {
                    required: 'Wymagane pole',
                  })}
                  bg="white"
                />
                <FormErrorMessage color="red.500">
                  {errors.firstName?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.lastName}>
                <FormLabel
                  htmlFor="lastName"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Nazwisko
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="lastName"
                  placeholder="Twoje nazwisko"
                  {...register('lastName', {
                    required: 'Wymagane pole',
                  })}
                />
                <FormErrorMessage>
                  {errors.lastName?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.email}>
                <FormLabel htmlFor="email" color="text.500" mb="10px" mt="20px">
                  Email
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="email"
                  placeholder="Adres e-mail"
                  {...register('email', {
                    required: 'Wymagane pole',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Nieprawidłowy adres email',
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.email?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.phone}>
                <FormLabel htmlFor="phone" color="text.500" mb="10px" mt="20px">
                  Numer telefonu
                  <RedAsterisk />
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon>+48</InputLeftAddon>
                  <Input
                    id="phone"
                    placeholder="Twój numer telefonu"
                    {...register('phone', {
                      required: 'Wymagane pole',
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>
                  {errors.phone?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <Box position="relative" paddingY={5} mt={10} mb={10}>
                <Divider />
                <AbsoluteCenter bg="white" px="4" textAlign="center">
                  Informacje o firmie
                </AbsoluteCenter>
              </Box>
              {/* <Box mb={10}>
                <Text fontSize="sm" align="center" fontStyle="italic">
                  Dzięki temu przypiszemy Ci najlepszego członka zespołu do
                  wsparcia na podstawie jego doświadczenia.
                </Text>
              </Box> */}

              <FormControl isInvalid={!!errors.companyName}>
                <FormLabel
                  htmlFor="companyName"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Nazwa firmy
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="companyName"
                  placeholder="Nazwa Twojej firmy"
                  {...register('companyName', {
                    required: 'Wymagane pole',
                  })}
                />
                <FormErrorMessage>
                  {errors.companyName?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.companyWebsite}>
                <FormLabel
                  htmlFor="companyWebsite"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Strona internetowa firmy
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="companyWebsite"
                  placeholder="Link do strony"
                  {...register('companyWebsite', {
                    required: 'Wymagane pole',
                  })}
                />
                <FormErrorMessage>
                  {errors.companyWebsite?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.companySize}>
                <FormLabel
                  htmlFor="companySize"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Wielkość firmy
                  <RedAsterisk />
                </FormLabel>
                <Select
                  placeholder="Wybierz jedną"
                  {...register('companySize', {
                    required: 'Wymagane pole',
                  })}
                >
                  <option value={0}>0-10</option>
                  <option value={1}>11-25</option>
                  <option value={2}>26+</option>
                </Select>
                <FormErrorMessage>
                  {errors.companySize?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <>
                <Box position="relative" paddingY={5} mt={10}>
                  <Divider />
                  <AbsoluteCenter bg="white" px="4" textAlign="center">
                    Pakiet
                  </AbsoluteCenter>
                </Box>
                <Box mb={10}>
                  <Text fontSize="sm" align="center" fontStyle="italic">
                    Wybierz typ subskrypcji, który Cię interesuje!
                  </Text>
                </Box>

                <FormControl isInvalid={!!errors.package}>
                  <FormLabel
                    htmlFor="package"
                    color="text.500"
                    mb="10px"
                    mt="20px"
                  >
                    Typ
                    <RedAsterisk />
                  </FormLabel>
                  <Select
                    placeholder="Wybierz jedną"
                    {...register('package', {
                      required: 'Wymagane pole',
                    })}
                  >
                    <option value={jobPackages['pro']}>
                      {jobPackages['pro']}
                    </option>
                    <option value={jobPackages['basic']}>
                      {jobPackages['basic']}
                    </option>
                    <option value={jobPackages['premium']}>
                      {jobPackages['premium']}
                    </option>
                  </Select>
                  <FormErrorMessage>
                    {errors.package?.message?.toString()}
                  </FormErrorMessage>

                  <Box mb={10} mt={15}>
                    <Center>
                      <Link
                        href="https://theraplo.com/cennik/"
                        target="_blank"
                        isExternal
                        fontSize="sm"
                        textDecor="underline"
                      >
                        Dowiedz się więcej o typach subskrypcji na naszej
                        stronie!
                      </Link>
                    </Center>
                  </Box>
                </FormControl>
              </>

              <Center mt="30px" mb="30px">
                <Button
                  mt={4}
                  backgroundColor="#00CC61"
                  fontWeight={'600'}
                  lineHeight={'1.125em'}
                  borderRadius={'1000px'}
                  color="white"
                  isLoading={isSubmitting}
                  loadingText="Zapisywanie..."
                  type="submit"
                >
                  Zaczynamy!
                </Button>
              </Center>
            </form>
          </VStack>
        </Box>
      </Center>
    </>
  );
}
